:root {
  --mainOpacity: rgba(231, 227, 227, 0.05);
}

/*  .header {
    background-image: linear-gradient( rgba(245, 240, 240, 0.1), rgba(193, 187, 187, 0.1) ), url("/public/background-bubbles@2x.png");
    opacity: var(--mainOpacity);
    height: 25em !important;
}  */

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a {
  color: #FFFF;
  text-decoration: underline;
}

.main-color {
  background-color: #0e76a8;
}

.col-image-left {
  background-image: url("/public/network.svg");
  height: 25em !important;
  background-size: contain;
  background-repeat: no-repeat;
}

.h1_rc {
  font-style: italic;
  font-family: Noto Serif Display sans-serif;
  

  --tw-text-opacity: 1;
  color: rgb(4 136 167/var(--tw-text-opacity));

}

.h12rc {
  font-style: italic;
  font-family: Noto Serif Display sans-serif;
  

  

}

.h4_rcFootnote {
  font-style: italic;
  font-family: Noto Serif Display sans-serif;
  color: #FFF;

}

.rc_footerPara {
  color: #FFF;
}

.hp_button {
  border-radius: 999px;
  
  
  background-color: #0e76a8;
  color:#f3f4f7;

  

}

.hp_navlink {
  
    display: block;
    padding: .5rem 1rem;
    color: #FFF;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

}

.col-image-right {
  background-image: url("/public/bubbles.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 25em !important;
}

.lost-image {
  background-image: url("./Images/PublicImages/image-3.jpg");
  height: 25em !important;
  width: "720"
}

.homepage-carousel-title {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.book-div {
  width: 480px;
  height: 384px;
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

.footertLink {
  color: white;
}


.switch {
  position: relative;
  height: 20px;
  width: 45px;
  display: inline-block;
  margin: 5px 10px;
}

.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  background-color:#878787;
  background-image: linear-gradient(315deg, #878787 0%, #a9a9a9 74%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 26px;
  text-align: center;
  box-shadow: 2px 5px 3px 1px #f3f4f7;
}

.slider:before {
  position: absolute;
  height: 12px;
  width: 12px;
  content: "";
  font-size: 10px;
  background-color: #f3f4f7;
  background-image: linear-gradient(315deg, #f3f4f7 0%, #caccd1 74%);
  top: 4px;
  left: 4px;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #00b712;
  background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
}

input:checked+.slider:before {
  transform: translateX(26px);
  transition: 0.4s;
  content: "";
  font-size: 10px;
  margin: 0 auto;
}

.label_txt_unchecked {
  /* color: #993333; */
  font-size: 21px;
  margin: 0px;
  transition: 0.4s;
}

.label_txt_checked {
  color: #00b712;
  font-size: 21px;
  margin: 0px;
  transition: 0.4s;
}

.label_txt {
  margin: 0px 5px;
}


.full-screen-map {
  height: 100vh;
}

.bg-newInfocolor {
  background-color: #0e76a8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}